<template>
  <InternalLayout>
    <template slot="body">
      <div class="margin-container properties-section">
        <PropertyCard
          v-for="(property, index) in getProperties"
          :key="`property-card-${index}`"
          :property="property"
          :is-favorite="
            getUser ? getUser.favorites.includes(property._id) : false
          "
          @click="
            $router.push({
              name: 'property-detail',
              params: {
                id: property._id,
              },
            })
          "
          @favorite="favoriteProperty(property._id)"
        />
      </div>
    </template>
  </InternalLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { PropertyCard } from 'ff-components-lib';

import InternalLayout from '@/components/layout/internal/InternalLayout';

export default {
  name: 'FavoritesScreen',
  components: {
    InternalLayout,
    PropertyCard,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getProperties', 'getUser']),
  },
  async created() {
    this.$store.dispatch('storeLoading', true);
    await this.$store.dispatch('storeUserFavorites', this.getUser.email);
    this.$store.dispatch('storeLoading', false);
  },
  methods: {
    async favoriteProperty(propertyId) {
      await this.$store.dispatch('updateUserFavorites', {
        user: this.getUser.email,
        property: propertyId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.properties-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    margin: 0 36px;
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 900px) {
  .properties-section {
    .card {
      margin: 0 26px;
      margin-bottom: 52px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .properties-section {
    .card {
      margin: 0;
      margin-bottom: 56px;
    }
  }
}
</style>
